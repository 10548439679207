//

$(document).ready(function() {

	$('#order-form').each(function() {
		var prefix = "order_form.";
		$('input[type=text]',this).each(function() {
			var name = prefix + $(this).attr('name');
			var storedValue = window.localStorage.getItem(name);
			var formValue = $(this).val();
			if (storedValue != null && !formValue) {
				$(this).val(storedValue);
			}
		});
		$('input[type=radio]',this).each(function() {
			var nameAttr = $(this).attr('name');
			var name = prefix + nameAttr;
			var storedValue = window.localStorage.getItem(name);
			var isChecked = $('input[name='+nameAttr+']:checked',this).length > 0;
			if (storedValue != null && !isChecked) {
				$('input[name='+nameAttr+'][value='+storedValue+']').prop('checked',true);
			}
		});	
		$('form',this).submit(function() {
			$('input[type=text]',this).each(function() {
				var name = prefix + $(this).attr('name');
				window.localStorage.setItem(name,$(this).val());
			});
			$('input[type=radio]:checked',this).each(function() {
				var name = prefix + $(this).attr('name');				
				window.localStorage.setItem(name,$(this).val());
			});
		});
	});


	$('#plan svg g').each(function() {
		var id = $(this).attr('id');
		if (PlanData[id]) {
			$(this).addClass('stand');
			$(this).addClass('stand-'+PlanData[id].state);
			}
	});

	$('#plan svg g.stand').click(function(e) {
		var id = $(this).attr('id');
		if (PlanData[id] && PlanData[id].state == 'free') {
			window.location.href = PlanData[id].url;
		}
	});

	$.each(PlanData,function(id,data) {
		if (data.state == 'free') {
			let $elem = $('svg #' + id);
	    	$elem.tooltipster({
	    		interactive: true,
	    		onlyOne: true,
	    		offsetX: 10,
                content: $('<div><p><strong>Standplatz Nr. ' + data.number + '<strong></p><p><br/>' + data.description + '<br/><br/>' + data.price + ',- €<br/><br/><a href="'+data.url+'" class="btn btn-primary">Zur Buchung</a></span>')
        	});
		}
	});

});